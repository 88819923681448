<template>
    <div class="settings-hold">
        <form @submit.prevent="saveSettings" v-if="config">
            <!-- Restart Terminals Button -->
            <div class="card mb-4" v-if="isAdmin">
                <div class="card-header">
                    <i class="fas fa-cogs"></i> Admin Actions
                </div>
                <div class="card-body">
                    <button type="button" class="btn btn-danger" @click="handleRestartTerminals">
                        Restart Terminals
                    </button>
                    <br /><br />
                    <!--<div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.useAiNames" id="useAiNames">
                        <label class="form-check-label" for="useAiNames"
                            v-tooltip="'Use AI to enhance modifier names for better readability'">
                            Use AI-Enhanced Names
                        </label>
                    </div>
                    <button v-if="config?.useAiNames" type="button" class="btn btn-primary"
                        @click="handleEnhanceModifiers" :disabled="isEnhancing">
                        <span v-if="isEnhancing" class="spinner-border spinner-border-sm me-2" role="status"
                            aria-hidden="true"></span>
                        {{ isEnhancing ? 'Enhancing Names...' : 'Enhance Modifier Names' }}
                    </button>
                    <small v-if="config?.useAiNames" class="d-block text-muted mt-2">
                        Uses AI to make modifier set names more customer-friendly
                    </small>-->
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.useWizard" id="useWizard">
                        <label class="form-check-label" for="useWizard"
                            v-tooltip="'Show item ordering in a step-by-step wizard'">Wizard Mode for Item Ordering
                        </label>
                    </div>
                    <br /><br />
                    <div class="mb-3">
                        <label for="accessToken" class="form-label">Access Token</label>
                        <input type="text" class="form-control" id="accessToken" v-model="config.accessToken"
                            placeholder="Enter your access token" :aria-describedby="'accessTokenHelp'">
                        <small id="accessTokenHelp" class="form-text text-muted">Your gateway access token for secure
                            transactions.</small>
                    </div>

                    <!-- Dropdown for OLO and STM -->
                    <div class="mt-3">
                        <label for="railSelection" class="form-label">Select Rails</label>
                        <select id="railSelection" class="form-select" v-model="config.rails">
                            <option value="olo">OLO</option>
                            <option value="stm">STM</option>
                        </select>
                    </div>
                    <div v-if="!config.usesOnboard" class="mt-3">
                        <label for="pinPadLocation" class="form-label">Credit Payment Method</label>
                        <select id="pinPadLocation" class="form-select" v-model="config.pinPadLocation">
                            <option value="right">PIN Pad on Right</option>
                            <option value="left">PIN Pad on Left</option>
                            <option value="onboard">Onboard Reader</option>
                        </select>
                    </div>

                    <!-- Payment Engagement Settings -->
                    <div class="mt-3">
                        <label for="paymentEngagement" class="form-label">Payment Engagement Mode</label>
                        <select id="paymentEngagement" class="form-select" v-model="config.startPaymentInstantly">
                            <option :value="true">Engage Payment Device Instantly at Checkout</option>
                            <option :value="false">Show Pay Button</option>
                        </select>
                    </div>

                    <br />

                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.rumSessions" id="rumSessions">
                        <label class="form-check-label" for="rumSessions"
                            v-tooltip="'Enable Datadog RUM sessions for enhanced troubleshooting'">Enable RUM
                            Sessions</label>
                    </div>

                    <!-- New setting for usesAutomaticNoUi -->
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.usesAutomaticNoUi" id="usesAutomaticNoUi">
                        <label class="form-check-label" for="usesAutomaticNoUi"
                            v-tooltip="'Toggle automatic UI handling for NO modifiers'">Use Automatic Modifier Set NO UI</label>
                    </div>
                </div>
            </div>

            <!-- General Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-sliders-h"></i> General Settings
                </div>
                <div class="card-body">
                    <!--<div class="mb-3">
                        <label for="loaderType" class="form-label" v-tooltip="'Choose the loader type'">Loader
                            Type</label>
                        <select v-model="config.loaderType" class="form-select" id="loaderType">
                            <option value="spinner">Spinner</option>
                            <option value="bar">Bar</option>
                        </select>
                    </div>-->
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.active" id="active">
                        <label class="form-check-label" for="active"
                            v-tooltip="'Toggle system activity status'">Active</label>
                    </div>
                    <div v-if="!config.active" class="mb-3">
                        <label for="deactivatedMessage" class="form-label"
                            v-tooltip="'Message displayed when system is inactive'">Deactivation Message</label>
                        <input type="text" class="form-control" id="deactivatedMessage"
                            v-model="config.text.deactivatedMessage"
                            :class="{ 'is-invalid': isDeactivationMessageInvalid }" required>
                        <div v-if="isDeactivationMessageInvalid" class="invalid-feedback">
                            Deactivation message is required when the system is inactive.
                        </div>
                    </div>
                </div>
            </div>

            <!-- Appearance Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-paint-brush"></i> Appearance Settings
                </div>
                <div class="card-body">
                    <div class="logo-upload-section mb-4">
                        <label class="form-label d-block mb-3" v-tooltip="'Upload a logo for the system'">
                            <strong>Location Logo</strong>
                        </label>

                        <div class="row align-items-start">
                            <!-- Logo Preview Column -->
                            <div class="col-md-4 text-center mb-3 mb-md-0">
                                <div class="logo-preview-wrapper p-3 border rounded">
                                    <h6 class="text-muted mb-3">Logo Preview</h6>
                                    <div class="logo-preview-container mx-auto" style="width: 150px; height: 150px;">
                                        <location-logo v-if="logoPreview || (config.logo && config?.logo?.url)"
                                            :logo="logoPreview || config?.logo?.url" name="Preview"
                                            :shape="config?.logo?.shape || 'circle'" />
                                        <div v-else
                                            class="placeholder-logo d-flex align-items-center justify-content-center h-100 bg-light rounded">
                                            <i class="fas fa-image text-muted fa-3x"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Upload Controls Column -->
                            <div class="col-md-8">
                                <div class="upload-controls p-3 border rounded">
                                    <!-- Upload Input -->
                                    <div v-if="!logoPreview && !config.logo?.url" class="mb-3">
                                        <label for="logoUpload" class="form-label">Upload Logo</label>
                                        <input type="file" class="form-control" id="logoUpload"
                                            @change="handleLogoUpload" accept=".jpg,.jpeg,.png,.gif,.bmp" />
                                        <small class="form-text text-muted">
                                            Max file size: 5MB. Accepted formats: JPEG, PNG, GIF, BMP
                                        </small>
                                    </div>

                                    <!-- Logo Shape Selection -->
                                    <div v-if="logoPreview || config.logo?.url" class="mb-3">
                                        <label for="logoShape" class="form-label">Logo Shape</label>
                                        <select v-model="config.logo.shape" class="form-select" id="logoShape">
                                            <option value="circle">Circle</option>
                                            <option value="square">Square</option>
                                        </select>
                                    </div>

                                    <!-- Delete Button -->
                                    <div v-if="logoPreview || config.logo?.url" class="mt-3">
                                        <button type="button" class="btn btn-outline-danger" @click="removeLogo">
                                            <i class="fas fa-trash-alt me-2"></i>Delete Logo
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="brandColor" class="form-label" v-tooltip.bottom="'Select a brand color'">Brand
                            Color</label>
                        <div class="d-flex flex-wrap">
                            <div v-for="color in predefinedColors" :key="color.name" class="color-chip"
                                :style="{ backgroundColor: color.value }"
                                :class="{ 'selected': config.template.brandColor === color.value }"
                                @click="selectColor(color.value)" v-tooltip="color.name">
                            </div>
                        </div>
                        <br />
                        <input type="color" class="form-control form-control-color ml-2" id="brandColorPicker"
                            v-model="config.template.brandColor" @change="validateColor">
                    </div>
                    <!--
                    <div class="mb-3">
                        
                        <label for="templateStyle" class="form-label"
                            v-tooltip.bottom="'Select a template style'">Template
                            Style</label>
                        <select v-model="config.template.name" class="form-select" id="templateStyle">
                            <option value="default">Default</option>
                            <option value="sharp">Sharp Edges</option>
                        </select>
                    </div>
                    
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.darkMode" id="darkMode">
                        <label class="form-check-label" for="darkMode" v-tooltip="'Enable dark mode for the UI'">Dark
                            Mode</label>
                    </div>
                    -->
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.icons" id="icons">
                        <label class="form-check-label" for="icons" v-tooltip="'Use Icons in the system'">Use
                            Icons</label>
                    </div>
                </div>
            </div>

            <!-- Concepts Settings
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-lightbulb"></i> Concepts
                </div>
                <div class="card-body">
                    <concepts-manager v-model="config.concepts" />
                </div>
            </div>
             -->

            <!-- Screensaver Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-tv"></i> Screensaver Settings
                </div>
                <div class="card-body">
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.screensaverEnabled"
                            id="screensaverEnabled">
                        <label class="form-check-label" for="screensaverEnabled"
                            v-tooltip="'Enable screensaver after inactivity'">Screensaver</label>
                    </div>


                    <div class="mb-3" v-if="config.screensaverEnabled">
                        <label for="screensaverBackgroundType" class="form-label"
                            v-tooltip="'Select the background type for the screensaver'">Screensaver Background
                            Type</label>
                        <select class="form-select" v-model="config.screensaverBackgroundType"
                            id="screensaverBackgroundType">
                            <option value="slideshow">Slideshow</option>
                            <option value="particles">Particles</option>
                            <option value="video">Video</option>
                        </select>
                    </div>
                    <div v-if="config.screensaverEnabled && config.screensaverBackgroundType === 'slideshow'">
                        <p class="text-muted mb-3">
                            <i class="fas fa-info-circle me-2"></i>
                            Upload and arrange images that will display when the kiosk is idle. Drag images to reorder
                            them.
                        </p>
                        <SlideshowSettings 
                            :config="config"
                            @update="handleSlideshowUpdate"
                            @validation="handleSlideshowValidation"
                        />
                        <div v-if="!isSlideshowValid" class="alert alert-warning mt-2">
                            <i class="fas fa-exclamation-triangle me-2"></i>
                            Please add at least one image to use slideshow mode
                        </div>
                    </div>
                    <div class="mb-3" v-if="config.screensaverEnabled && config.screensaverBackgroundType === 'video'">
                        <div class="row align-items-start">
                            <!-- Video Preview Column -->
                            <div class="col-md-6 mb-3 mb-md-0">
                                <div class="video-preview-wrapper p-3 border rounded">
                                    <h6 class="text-muted mb-3">Video Preview</h6>
                                    <div class="video-preview-container">
                                        <div v-if="config.screensaverVideoSource" class="position-relative">
                                            <video class="w-100 rounded" controls
                                                style="max-height: 300px; object-fit: contain;">
                                                <source :src="config.screensaverVideoSource" type="video/mp4">
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <div v-else
                                            class="placeholder-video d-flex align-items-center justify-content-center bg-light rounded"
                                            style="height: 200px;">
                                            <div class="text-center">
                                                <i class="fas fa-film text-muted fa-3x mb-2"></i>
                                                <p class="text-muted mb-0">No video uploaded</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Upload Controls Column -->
                            <div class="col-md-6">
                                <div class="upload-controls p-3 border rounded h-100">
                                    <h6 class="text-muted mb-3">Video Settings</h6>

                                    <!-- Loading State -->
                                    <div v-if="isUploading" class="text-center py-4">
                                        <div class="spinner-border text-primary mb-2" role="status">
                                            <span class="visually-hidden">Uploading...</span>
                                        </div>
                                        <p class="text-muted mb-0">Uploading video...</p>
                                    </div>

                                    <!-- Upload Controls -->
                                    <div v-else>
                                        <!-- Upload Input -->
                                        <div v-if="!config.screensaverVideoSource" class="mb-3">
                                            <label for="screensaverVideoUpload" class="form-label">Upload Screensaver
                                                Video</label>
                                            <input type="file" class="form-control" id="screensaverVideoUpload"
                                                @change="handleScreensaverVideoUpload" accept="video/mp4">
                                            <div class="mt-2">
                                                <small class="form-text text-muted d-block">
                                                    <i class="fas fa-info-circle me-1"></i>
                                                    Max file size: 20MB
                                                </small>
                                                <small class="form-text text-muted d-block">
                                                    <i class="fas fa-check-circle me-1"></i>
                                                    Accepted format: MP4
                                                </small>
                                            </div>
                                        </div>

                                        <!-- Delete Button -->
                                        <div v-if="config.screensaverVideoSource" class="d-grid gap-2">
                                            <button type="button" class="btn btn-outline-danger"
                                                @click="handleDeleteScreensaverVideo">
                                                <i class="fas fa-trash-alt me-2"></i>Delete Video
                                            </button>
                                            <small class="form-text text-muted text-center mt-2">
                                                Upload a new video to replace the current one
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-check form-switch mb-3" v-if="config.screensaverEnabled">
                        <input class="form-check-input" type="checkbox" v-model="config.screensaverShowText"
                            id="screensaverShowText">
                        <label class="form-check-label" for="screensaverShowText"
                            v-tooltip="'Show text on screensaver'">Show Screensaver Text</label>
                    </div>
                    <!-- Add screensaver text customization fields -->
                    <div v-if="config.screensaverEnabled && config.screensaverShowText" class="mb-4">
                        <div class="mb-3">
                            <label for="screensaverTitle" class="form-label">Screensaver Title</label>
                            <input type="text" class="form-control" id="screensaverTitle"
                                v-model="config.text.startOrder" placeholder="e.g., Touch to Start">
                        </div>
                        <div class="mb-3">
                            <label for="screensaverSubtext" class="form-label">Screensaver Subtext</label>
                            <input type="text" class="form-control" id="screensaverSubtext"
                                v-model="config.text.startOrderSubtext" placeholder="e.g., Place your order here">
                            <small class="form-text text-muted">
                                This text appears below the main title on the screensaver
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Menu Settings 
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-bars"></i> Menu Settings
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label for="menu_cols" class="form-label"
                            v-tooltip="'Set the number of columns for menu display'">Menu Columns</label>
                        <input type="number" class="form-control" id="menu_cols" v-model.number="config.menu_cols">
                    </div>
                </div>
            </div>
            -->


            <!-- Language Support Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-language"></i> Language Settings
                </div>
                <div class="card-body">
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.languageSupport.enabled"
                            id="languageSupportEnabled">
                        <label class="form-check-label" for="languageSupportEnabled"
                            v-tooltip="'Toggle language support'">Enable Language Support</label>
                    </div>
                    <div v-if="config.languageSupport.enabled" class="mb-3">
                        <label class="form-label" v-tooltip="'Select supported languages'">Supported
                            Languages</label><br />
                        <div v-for="language in languageOptions" :key="language.key"
                            class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" :id="language.key" :value="language.key"
                                v-model="config.languageSupport.supportedLanguages">
                            <label class="form-check-label" :for="language.key">{{ language.name }}</label>
                        </div>
                    </div>
                    <div v-if="config.languageSupport.enabled" class="mb-3">
                        <label for="defaultLanguage" class="form-label" v-tooltip="'Select default language'">Default
                            Language</label>
                        <select id="defaultLanguage" class="form-select"
                            v-model="config.languageSupport.defaultLanguage">
                            <option v-for="language in config.languageSupport.supportedLanguages" :key="language"
                                :value="language">
                                {{ getLanguageName(language) }}
                            </option>
                        </select>
                    </div>
                    <div v-if="config.languageSupport.enabled && isAdmin" class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.languageSupport.translateMenu"
                            id="translateMenu">
                        <label class="form-check-label" for="translateMenu"
                            v-tooltip="'Toggle menu translation'">Translate Menus</label>
                    </div>
                </div>
            </div>


            <!-- Order Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-receipt"></i> Order Settings
                </div>
                <div class="card-body">
                    <!-- Timeout Settings -->
                    <div class="mb-3">
                        <label for="idleTimeoutSeconds" class="form-label"
                            v-tooltip="'Set the idle timeout in seconds'">Idle Timeout (seconds)</label>
                        <div class="input-group">
                            <button class="btn btn-outline-secondary" type="button"
                                @click="changeTimeout(-10)">-</button>
                            <input type="number" class="form-control text-center"
                                v-model.number="config.idleTimeoutSeconds">
                            <button class="btn btn-outline-secondary" type="button"
                                @click="changeTimeout(10)">+</button>
                        </div>
                    </div>
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.allowSpecialRequests"
                            id="allowSpecialRequests">
                        <label class="form-check-label" for="allowSpecialRequests"
                            v-tooltip="'Allow customers to make special requests on items'">Allow Special
                            Requests</label>
                    </div>
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.orderSummaryShowModifiers"
                            id="orderSummaryShowModifiers">
                        <label class="form-check-label" for="orderSummaryShowModifiers"
                            v-tooltip="'Show modifiers in order summary'">Show Modifiers in Order Summary</label>
                    </div>
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.ratingPromptEnabled"
                            id="ratingPromptEnabled">
                        <label class="form-check-label" for="ratingPromptEnabled"
                            v-tooltip="'Enable rating prompt after order completion'">Ask Users to Rate Their
                            Experience</label>
                    </div>
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.ignoreMenuSchedules" id="ignoreMenuSchedules">
                        <label class="form-check-label" for="ignoreMenuSchedules"
                            v-tooltip="'Show all menu items regardless of their scheduled availability'">
                            Ignore Menu Schedules (Show all schedules, all of the time)
                        </label>
                    </div>
                    <div>
                        <!--
                        <div class="form-check form-switch mb-3">
                            <input class="form-check-input" type="checkbox" v-model="config.phoneLogin" id="phoneLogin">
                            <label class="form-check-label" for="phoneLogin"
                                v-tooltip="'Allow users to sign in with their phone number'">
                                Phone Login
                            </label>
                        </div>
                        <p class="text-muted">
                            When enabled, users will be able to sign in using their phone number. The system will
                            remember
                            their name, email, and previously ordered items for a more personalized experience.
                        </p>
-->
                    </div>
                    <div class="form-check form-switch mb-3" v-if="config.rails === 'stm'">
                        <input class="form-check-input" type="checkbox" v-model="config.hideQuantityControl" id="hideQuantityControl">
                        <label class="form-check-label" for="hideQuantityControl"
                            v-tooltip="'Hide quantity control in order summary'">Hide Quantity Control</label>
                    </div>
                </div>
            </div>

            <!-- Text Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-font"></i> Customize Text
                </div>
                <div class="card-body">
                    <ul class="nav nav-tabs" id="textTabs" role="tablist">
                        <li class="nav-item" role="presentation" v-for="(category, index) in textCategories"
                            :key="index">
                            <button class="nav-link" :class="{ active: index === activeTab }" :id="`tab-${index}`"
                                data-bs-toggle="tab" :data-bs-target="`#content-${index}`" type="button" role="tab"
                                :aria-controls="`content-${index}`" :aria-selected="index === activeTab"
                                @click="activeTab = index">
                                {{ category.title }}
                            </button>
                        </li>
                    </ul>

                    <div class="tab-content pt-4" id="textTabsContent">
                        <div v-for="(category, index) in textCategories" :key="index" class="tab-pane fade"
                            :class="{ 'show active': index === activeTab }" :id="`content-${index}`" role="tabpanel"
                            :aria-labelledby="`tab-${index}`">
                            <div v-for="(value, key) in getTextSettings(category.keys)" :key="key" class="mb-3">
                                <label :for="key" class="form-label">{{ toTitleCase(key.split(/(?=[A-Z])/).join(' '))
                                    }}</label>
                                <textarea
                                    v-if="shouldUseTextarea(key)"
                                    class="form-control"
                                    :id="key"
                                    v-model="config.text[key]"
                                    @input="validateText(key)"
                                    @keyup="handleKeyup($event, key)"
                                    rows="3"
                                    :ref="key"
                                ></textarea>
                                <input
                                    v-else
                                    type="text"
                                    class="form-control"
                                    :id="key"
                                    v-model="config.text[key]"
                                    @input="validateText(key)"
                                    @keyup="handleKeyup($event, key)"
                                    :ref="key"
                                >
                                <div v-if="textErrors[key]" class="text-danger">{{ textErrors[key] }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
        <!-- Save Button -->
        <save-button :isDirty="isSettingsDirty" @save="saveSettings" :stickyBottom="true"
            :serverDone="serverDone"></save-button>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SaveButton from '@/components/features/admin/SaveButton.vue';
import LocationLogo from '@/components/global/LocationLogo.vue';
import { toTitleCase } from '@/utils/helpers';
import { Tooltip } from 'bootstrap';
import tooltipDirective from '@/directives/tooltip';
import SlideshowSettings from '@/components/features/admin/SlideshowSettings.vue';
//import ConceptsManager from './ConceptsComponent.vue';

export default {
    directives: {
        tooltip: tooltipDirective
    },
    components: {
        SaveButton,
        LocationLogo,
        SlideshowSettings,
        //ConceptsManager,
    },
    computed: {
        ...mapState('admin', ['config']),
        ...mapGetters('admin', ['isAdmin']),
        isSettingsDirty() {
            return JSON.stringify(this.config) !== JSON.stringify(this.originalSettings) || this.logoPreview != null;
        },
        isDeactivationMessageInvalid() {
            return !this.config.active && !this.config.text.deactivatedMessage;
        },
        textCategories() {
            return [
                {
                    title: 'Order Process',
                    keys: [
                        'startOrder', 'startOrderSubtext', 'selectOrderTypeQuestion', 'submittingOrder',
                        'submitOrderButtonText', 'orderNumber', 'idleTriggeredMessage', 'startNewOrder',
                        'startOverButtonText', 'welcomeMessage'
                    ]
                },
                {
                    title: 'Payment',
                    keys: [
                        'pay', 'totalDue', 'howWouldYouLikeToPay', 'creditInstructions', 'pinPadInstructions',
                        'paymentUnsuccessful', 'payUpFrontTitle', 'payUpFrontInstructions', 'tipPrompt',
                        'tipQuestion', 'customTip', 'noTip'
                    ]
                },
                {
                    title: 'Cart',
                    keys: [
                        'cartTitle', 'emptyCartMessage', 'addToCart', 'updateItem', 'chooseOne',
                        'chooseAtLeast', 'chooseBetween', 'chooseUpTo', 'optional', 'required',
                        'specialInstructions', 'specialRequestInstructions'
                    ]
                },
                {
                    title: 'Receipt',
                    keys: [
                        'receipt', 'receiptText', 'emailReceipt', 'smsReceipt', 'receiptMessage'
                    ]
                },
                {
                    title: 'General',
                    keys: [
                        'done', 'apply', 'choose', 'loading', 'complete', 'thankYou', 'tryAgain',
                        'validPhone', 'cancelOrder', 'cancelButtonText', 'submitButtonText',
                        'continueButtonText', 'backButtonText', 'skipButtonText', 'ratingPrompt',
                        'badgeQuestion', 'emailQuestion', 'outOfStock', 'couponCode',
                        'couponCodeInstructions', 'deactivatedMessage'
                    ]
                },
                {
                    title: 'Other',
                    keys: [] // This will be populated dynamically
                }
            ];
        },
        otherTextSettings() {
            const knownKeys = this.textCategories.flatMap(category => category.keys);
            return Object.keys(this.config.text).filter(key => !knownKeys.includes(key));
        }
    },
    data() {
        return {
            originalSettings: null,
            isDirty: false,
            logoPreview: null,
            logoUrl: '',
            logo: {
                url: null,
                shape: "circle"
            },
            predefinedColors: [
                { name: 'Black', value: '#232221' },
                { name: 'Shift4 Blue', value: '#0E5BF3' },
            ],
            languageSupport: {
                enabled: false,
                translateMenu: false,
                defaultLanguage: 'en',
                supportedLanguages: ['en', 'es', 'it', 'ko', 'fr', 'mr', 'zh', 'lt']
            },
            textErrors: {},
            serverDone: false,
            isUploading: false,
            isEnhancing: false,
            languageOptions: [
                { key: 'en', name: 'English' },
                { key: 'es', name: 'Spanish' },
                { key: 'it', name: 'Italian' },
                { key: 'ko', name: 'Korean' },
                { key: 'fr', name: 'French' },
                { key: 'zh', name: 'Chinese' },
                { key: 'lt', name: 'Lithuanian' },
                { key: 'ja', name: 'Japanese' }
            ],
            activeTab: 0,
            isSlideshowValid: true,
            textareaFields: new Set(),
            cursorPositions: {},
        };
    },
    methods: {
        ...mapActions('admin', ['fetchConfig', 'saveConfig', 'uploadLogo', 'deleteLogo', 'restartTerminals']),
        toTitleCase,
        hasValidationErrors() {
            return Object.values(this.textErrors).some(error => error);
        },
        handleKeyup(event, key) {
            this.cursorPositions[key] = event.target.selectionStart;
        },
        validateText(key) {
            const prevIsTextarea = this.shouldUseTextarea(key);
            const currentPos = this.cursorPositions[key];

            if (this.config.text[key].length > 200) {
                this.textErrors[key] = 'Text cannot exceed 200 characters.';
            } else {
                this.textErrors[key] = '';
            }
            
            // Check if we need to switch to textarea
            if (this.config.text[key].length > 40) {
                this.textareaFields.add(key);
            }

            // If the field type changed, restore the cursor position after the next DOM update
            if (prevIsTextarea !== this.shouldUseTextarea(key)) {
                this.$nextTick(() => {
                    const element = this.$refs[key];
                    if (element && element.length > 0) {
                        element[0].setSelectionRange(currentPos, currentPos);
                        element[0].focus();
                    }
                });
            }
        },
        handleLogoUpload(event) {
            const file = event.target.files[0];
            if (file) {
                if (file.size > 5 * 1024 * 1024) {
                    alert('File size exceeds the 5MB limit. Please choose a smaller file.');
                    event.target.value = ''; // Clear the file input
                    return;
                }
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.logoPreview = e.target.result;
                    this.isSettingsDirty = true;
                };
                reader.readAsDataURL(file);
            }
        },
        async saveSettings() {
            // Validate text fields
            for (let key in this.config.text) {
                this.validateText(key);
            }

            // Check for validation errors
            if (this.hasValidationErrors() || this.isDeactivationMessageInvalid) {
                this.serverDone = true;
                alert("Please correct the validation errors before saving.");
                return;
            }

            // Add validation check
            if (this.config.screensaverEnabled &&
                this.config.screensaverBackgroundType === 'slideshow' &&
                !this.isSlideshowValid) {
                this.serverDone = true;
                alert("Please add at least one image to use slideshow mode");
                return;
            }

            // Upload the logo if needed
            this.serverDone = false;
            if (this.logoPreview && this.logoPreview.startsWith('data:')) {
                const base64Data = this.logoPreview.split(',')[1];
                const byteString = atob(base64Data);
                const arrayBuffer = new ArrayBuffer(byteString.length);
                const uint8Array = new Uint8Array(arrayBuffer);
                for (let i = 0; i < byteString.length; i++) {
                    uint8Array[i] = byteString.charCodeAt(i);
                }
                const blob = new Blob([uint8Array], { type: 'image/png' });
                const formData = new FormData();
                formData.append('file', blob, 'logo.png');

                try {
                    const response = await this.uploadLogo(formData);
                    this.config.logo.url = response.url;
                } catch (error) {
                    console.error("Failed to upload logo:", error);
                    this.serverDone = true;
                    alert("Failed to upload logo. Please try again.");
                    return;
                }
            } else if (this.logoUrl) {
                this.config.logo.url = this.logoUrl;
            }

            try {
                await this.saveConfig(this.config);
                this.originalSettings = JSON.parse(JSON.stringify(this.config));
                this.logoPreview = null;
                this.isSettingsDirty = false;
            } catch (error) {
                console.error("Failed to save settings:", error);
                alert("Failed to save settings. Please try again.");
            }

            this.serverDone = true;
        },
        applyLogoUrl() {
            this.logoPreview = this.logoUrl;
        },
        removeLogo() {
            this.logoPreview = null;
            this.config.logo.url = null;
            this.logoUrl = '';
        },
        selectColor(color) {
            this.config.template.brandColor = color;
            this.validateColor();
        },
        validateColor() {
            const isValid = checkContrast(this.config.template.brandColor);
            if (!isValid) {
                alert('Selected color does not meet ADA contrast guidelines against white. Please choose another color.');
            }
        },
        changeTimeout(val) {
            let newTimeout = this.config.idleTimeoutSeconds + val;
            if (newTimeout >= 0) {
                this.config.idleTimeoutSeconds = newTimeout;
            }
        },
        refreshTooltips() {
            const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            tooltips.forEach(el => {
                const tooltipInstance = Tooltip.getInstance(el);
                if (tooltipInstance) {
                    tooltipInstance.dispose();
                }
                new Tooltip(el);
            });
        },
        handleRestartTerminals() {
            this.restartTerminals()
                .then(() => {
                    alert("Restart command sent to terminals successfully.");
                })
                .catch((error) => {
                    console.error("Failed to send restart command:", error);
                    alert("Failed to send restart command. Please try again.");
                });
        },
        async handleScreensaverVideoUpload(event) {
            const file = event.target.files[0];
            if (file) {
                if (file.size > 20 * 1024 * 1024) {
                    alert('File size exceeds the 20MB limit. Please choose a smaller file.');
                    event.target.value = ''; // Clear the file input
                    return;
                }
                const formData = new FormData();
                formData.append('file', file);

                this.isUploading = true;
                try {
                    const response = await this.$store.dispatch('admin/uploadVideo', formData);
                    this.config.screensaverVideoSource = response.url;
                    alert('Screensaver video uploaded successfully.');
                } catch (error) {
                    alert('Failed to upload screensaver video. Please try again.');
                } finally {
                    this.isUploading = false;
                }
            }
        },
        async handleDeleteScreensaverVideo() {
            if (this.config.screensaverVideoSource) {
                try {
                    await this.$store.dispatch('admin/deleteLogo', { url: this.config.screensaverVideoSource });
                    this.config.screensaverVideoSource = null;
                    alert('Screensaver video deleted successfully.');
                } catch (error) {
                    alert('Failed to delete screensaver video. Please try again.');
                }
            }
        },
        async handleEnhanceModifiers() {
            this.isEnhancing = true;
            try {
                await this.$store.dispatch('admin/enhanceModifierNames');
                this.$toast.success('Modifier names enhanced successfully');
            } catch (error) {
                this.$toast.error('Failed to enhance modifier names');
                console.error('Enhancement error:', error);
            } finally {
                this.isEnhancing = false;
            }
        },
        getLanguageName(languageKey) {
            const language = this.languageOptions.find(lang => lang.key === languageKey);
            return language ? language.name : languageKey;
        },
        getTextSettings(keys) {
            return Object.fromEntries(
                Object.entries(this.config.text).filter(([key]) => keys.includes(key))
            );
        },
        handleSlideshowUpdate(slideshowConfig) {
            // Update all slideshow-related config properties
            this.config.screensaverImages = slideshowConfig.screensaverImages;
            this.config.screensaverRotationSpeed = slideshowConfig.screensaverRotationSpeed;
            this.config.screensaverTransition = slideshowConfig.screensaverTransition;
            this.config.screensaverShowText = slideshowConfig.screensaverShowText;
        },
        handleSlideshowValidation(isValid) {
            this.isSlideshowValid = isValid;
        },
        shouldUseTextarea(key) {
            const currentLength = this.config.text[key]?.length || 0;
            
            // If text is currently long or was previously marked for textarea
            if (currentLength > 40 || this.textareaFields.has(key)) {
                this.textareaFields.add(key); // Remember this field should use textarea
                return true;
            }
            
            return false;
        },
    },
    mounted() {
        const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltips.forEach(tooltip => {
            new Tooltip(tooltip);
        });
    },
    async created() {
        await this.fetchConfig();
        if (!this.config.logo) {
            this.config.logo = {
                url: null,
                shape: "circle"
            };
        }
        // Set default screensaver type to slideshow if screensaver is enabled
        if (this.config.screensaverEnabled && !this.config.screensaverBackgroundType) {
            this.config.screensaverBackgroundType = 'slideshow';
        }
        this.originalSettings = JSON.parse(JSON.stringify(this.config));
    },
    watch: {
        'config': {
            handler: 'refreshTooltips',
            deep: true,
            immediate: true
        }
    }
};

function hexToRgb(hex) {
    // Convert hex color to RGB
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
}

function sRGB(colorComponent) {
    // Convert color component to sRGB
    const component = colorComponent / 255;
    return component <= 0.03928 ? component / 12.92 : Math.pow((component + 0.055) / 1.055, 2.4);
}

function luminance(r, g, b) {
    // Calculate the relative luminance
    return 0.2126 * sRGB(r) + 0.7152 * sRGB(g) + 0.0722 * sRGB(b);
}

function checkContrast(colorHex, baseHex = '#FFFFFF') {
    const [r1, g1, b1] = hexToRgb(colorHex);
    const [r2, g2, b2] = hexToRgb(baseHex);

    const L1 = luminance(r1, g1, b1);
    const L2 = luminance(r2, g2, b2);

    const ratio = L1 > L2 ? (L1 + 0.05) / (L2 + 0.05) : (L2 + 0.05) / (L1 + 0.05);
    return ratio >= 4.5; // Correct ratio comparison for ADA compliance
}
</script>

<style scoped>
#receipt {
    max-width: 100% !important;
}

.logo-container {
    width: 100%;
    margin-right: 1rem;
}

.preview-button {
    border: none;
    border-radius: 100px;
    /* Rounded corners */
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.color-chip {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    border: 2px solid #fff;
}

.terminal-status {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.connected {
    background-color: green;
}

.disconnected {
    background-color: grey;
}

.color-chip.selected {
    border: 3px solid white;
    box-shadow: 0 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.sticky-bar {
    position: sticky;
    bottom: 2rem;
    background-color: white;
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    display: flex;
    justify-content: flex-end;
}

.loading-indicator {
    font-size: 16px;
    color: #007bff;
}

.logo-preview-wrapper {
    background-color: #f8f9fa;
}

.logo-preview-container {
    transition: all 0.3s ease;
}

.logo-preview-container:hover {
    transform: scale(1.05);
}

.placeholder-logo {
    border: 2px dashed #dee2e6;
}

.upload-controls {
    background-color: #f8f9fa;
    height: 100%;
}

.video-preview-wrapper {
    background-color: #f8f9fa;
}

.video-preview-container {
    transition: all 0.3s ease;
}

.placeholder-video {
    border: 2px dashed #dee2e6;
    transition: all 0.3s ease;
}

.placeholder-video:hover {
    border-color: #adb5bd;
    background-color: #e9ecef;
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}

/* Improve video controls visibility */
video::-webkit-media-controls {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 0.375rem 0.375rem;
}

/* Add some hover effect to the upload input */
.form-control:hover {
    border-color: #0d6efd;
}
</style>
