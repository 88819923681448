export default {
  namespaced: true,
  state: {
    visible: false,
    tipConfig: null,
  },
  getters: {
    visible: (state) => state.visible,
    tipOptionsOld: (state, getters, rootState) => {
      const tipConfig = rootState.location.config.tip;

      // set tip config
      state.tipConfig = tipConfig;

      const { subtotal, taxes } = rootState.order;


      const orderTaxes = taxes ? taxes : 0;

      console.log('Subtotal:', subtotal);
      console.log('Taxes:', orderTaxes);

      if (!tipConfig.calculationMethod) {
        tipConfig.calculationMethod = 'includeTaxes';
      }

      const baseAmount = tipConfig.calculationMethod === 'includeTaxes'
        ? subtotal + orderTaxes
        : subtotal;

      console.log('Base Amount for Tip Calculation:', baseAmount);

      const tipCalcs = tipConfig.options.map((percent) => {
        const amount = baseAmount * (percent / 100);
        console.log(`Tip Option: ${percent}%, Amount: ${amount}`);
        return { percent, amount };
      });

      return tipCalcs;
    },
    tipOptions: (state, getters, rootState) => {
      const tipConfig = rootState.location.config.tip;

      // set tip config
      state.tipConfig = tipConfig;

      // Check if gpattTicket exists and use its values if available
      const order = rootState.order;
      const hasGpattTicket = order.gpattTicket;
      const subtotal = hasGpattTicket ? parseFloat(order.gpattTicket.subtotal * 100) : order.subtotal;
      const taxes = hasGpattTicket ? parseFloat(order.gpattTicket.taxTotal * 100) : order.taxTotal || 0;

      // Log the subtotal and taxes
      console.log('Subtotal:', subtotal);
      console.log('Taxes:', taxes);

      if (!tipConfig.calculationMethod) {
        tipConfig.calculationMethod = 'includeTaxes';
      }

      const baseAmount = tipConfig.calculationMethod === 'includeTaxes'
        ? subtotal + taxes
        : subtotal;

      console.log('Base Amount for Tip Calculation:', baseAmount);

      const tipCalcs = tipConfig.options.map((percent) => {
        let amount = Math.round(baseAmount * (percent / 100));

        let offendsGatewayGods = false;
        let retries = 0;

        while (retries < 7) {
          const tipAmount = parseFloat(amount / 100).toFixed(2);
          const totalAmount = parseFloat(baseAmount / 100).toFixed(2);
          const lhTotal = parseFloat(totalAmount) + parseFloat(tipAmount);

          if (lhTotal.toString().split('.')[1]?.length > 2) {
            console.error(`Tip Option: ${percent}%, Amount: ${amount}, Decimal Places: ${lhTotal.toString().split('.')[1]?.length}`);
            offendsGatewayGods = true;
            amount -= 1; // Decrement tip by 1 penny
            retries++;
          } else {
            offendsGatewayGods = false;
            console.log(`Tip Option: ${percent}%, Amount: ${amount}, Decimal Places: ${lhTotal.toString().split('.')[1]?.length}`);
            break; // Exit loop if no error
          }
        }

        if (offendsGatewayGods) {
          console.error(`Failed to resolve floating point error after ${retries} retries.`);
        }

        // Ensure the tip amount is not below zero
        if (amount <= 0) {
          return null; // Exclude this option
        }

        return { percent, amount };
      }).filter(option => option !== null); // Filter out null options

      return tipCalcs;
    },
    tipConfig: (state) => state.tipConfig,
    selectedTip: (state) => state.selectedTip,
  },
  mutations: {
    LOAD_CONFIG(state, config) {
      state.tipConfig = config.tip;
    },
    RESET(state) {
      state.tipConfig = null;
    },
    SET_TIP_VISIBILITY(state, visible) {
      state.visible = visible;
    },
  },
  actions: {
    init({ commit, rootState }) {
      commit('LOAD_CONFIG', rootState.location.config);
    },
    show({ commit }) {
      commit("RESET");
      commit("SET_TIP_VISIBILITY", true);
    },
    hide({ commit }) {

      commit("SET_TIP_VISIBILITY", false);
    },
  },
};
