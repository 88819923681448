<template>
  <div :class="['modifier', { highlight: isSelected }]">
    <div @click="toggleSelection" :class="['mod-hold', { selected: isSelected }, { disabled: disabled }]">
      <div class="icon">
        <SvgIcon :name="control" />
      </div>
      <span :class="['name', { 'strikethrough': isStrikethrough }]">
        {{ modifier.name }}
        <p v-if="modifier.description" class="description">{{ modifier.description }}</p>
        <span class="extra" v-if="modifier.price">({{ formattedPrice }})</span>
        <span v-if="nestedModifierSummary" class="nested-modifier-summary">
          <br />
          {{ nestedModifierSummary }}
        </span>
      </span>
      <span v-if="hasNested" class="nested-indicator">
        <SvgIcon name="chevronRight" />
      </span>
    </div>
    <!-- Transition for nested modifiers -->
    <transition name="slide" @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div v-if="hasNested && isSelected && !nestedSaved" class="nested-modifiers">
        <div class="modal-header">
          <h4>{{ modifier.name }}</h4>
          <p v-if="modifier.description">{{ modifier.description }}</p>
        </div>
        <div class="modifier-set-hold">
          <NestedModifierSet v-for="nestedSet in modifier.modifierSets" :key="nestedSet.ref" :modifierSet="nestedSet"
            :parentModifierSetRef="parentModifierSet.ref" :parentModifierRef="modifier.ref"
            :selectedGraph="nestedSelectedModSets(nestedSet.ref)" />
            <div class="mod-controls d-flex">
          <button class="btn btn-lg btn-outline-primary flex-grow-1" @click="cancelNested">
            Cancel
          </button>
          <button class="btn btn-lg btn-primary flex-grow-1" @click="saveNested" :disabled="!valid">
            Save
          </button>
        </div>
        </div>

      </div>
    </transition>
  </div>
</template>


<script>
import NestedModifierSet from "./NestedModifierSet.vue";
import { formatCurrency } from "@/utils/helpers";
import svgLib from "@/utils/svgLib";
import SvgIcon from "@/components/global/SvgIcon.vue";

export default {
  components: {
    NestedModifierSet,
    SvgIcon,
  },
  data() {
    return {
      nestedSaved: false,
    };
  },
  props: {
    modifier: Object,
    parentModifierSet: Object,
    isSelected: Boolean,
    modifierType: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedGraph: Array,
    
  },
  computed: {
    control() {
      const type = this.modifierType === "checkbox" ? "checkbox" : "radio";
      const state = this.isSelected ? "on" : "off";
      return `${type}-${state}`;
    },
    hasNested() {
      return (
        this.modifier.modifierSets && this.modifier.modifierSets.length > 0
      );
    },
    formattedPrice() {
      return formatCurrency(this.modifier.price);
    },
    nestedModifierSummary() {
      let modifierNames = [];
      this.selectedGraph.forEach((set) => {
        set.lineModifiers.forEach((modifier) => {
          modifierNames.push(modifier.name);
        });
      });

      return modifierNames.join(", ");
    },
    valid() {
      for (const modSet of this.modifier.modifierSets) {
        const selected = this.nestedSelectedModSets(modSet.ref);
        if (selected.length < modSet.min || selected.length > modSet.max) {
          return false;
        }
      }
      return true;
    },
    isStrikethrough() {
      return this.usesAutomaticNoUi && !this.isSelected;
    },
  },
  methods: {
    beforeEnter(el) {
      el.classList.add('animate__animated', 'animate__slideInRight');
    },
    enter(el, done) {
      done();
    },
    leave(el, done) {
      el.classList.replace('animate__slideInRight', 'animate__slideOutRight');
      setTimeout(done, 1000);
    },
    cancelNested() {
      this.nestedSaved = true;
      this.$store.commit("orderItem/TOGGLE_MODIFIER", {
        modifierSetRef: this.parentModifierSet.ref,
        modifierSetName: this.parentModifierSet.name,
        modifier: this.modifier,
      });
    },
    saveNested() {
      this.nestedSaved = true;
      this.nestedSaved = true;
    },
    getSvg(name) {
      return svgLib[name];
    },
    toggleSelection() {
      if (this.disabled) return;
      this.nestedSaved = false;
      if (this.modifierType === "radio") {

        const wasSelected = this.isSelected;
        this.$store.commit("orderItem/UNSET_MODIFIERS_FOR_SET", {
          modifierSetRef: this.parentModifierSet.ref,
        });


        if (wasSelected) return;

      }

      this.$store.commit("orderItem/TOGGLE_MODIFIER", {
        modifierSetRef: this.parentModifierSet.ref,
        modifierSetName: this.parentModifierSet.name,
        modifier: this.modifier,
      });

    },
    nestedSelectedModSets(ref) {
      const lms = this.selectedGraph.find((lms) => lms.modifierSetRef === ref);
      return lms ? lms.lineModifiers : [];
    },
  },
};
</script>

<style scoped>
.nested-modifiers {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 38rem;
  height: 100%;
  background: var(--primary-white);
  overflow-y: auto;
  z-index: 9999;
  animation-duration: 0.1s;
}

.modifier-set-hold {
  height: 100%;
  overflow: visible;
}

.nested-modifier-summary {
  color: var(--grey-medium);
  font-weight: 100;
}

.mod-controls {
  position: sticky !important;
  bottom: -1px !important;
  left: 0;
  width: 100%;
  padding: 1.25rem 2.5rem;
  background: var(--primary-white);
  z-index: 10;
  display: flex;
  gap: 1rem;
  box-shadow: var(--box-shadow);
}

.mod-controls .btn {
  margin-right: 1rem;
  /* Add margin to create space between buttons */
}

.mod-controls .btn:last-child {
  margin-right: 0;
  /* Remove margin for the last button */
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.icon {
  width: 2.5rem !important;
  height: 2.5rem !important;
  margin-right: 0.5rem;
  align-self: flex-start;
}

.strikethrough {
  text-decoration: line-through !important;
}
</style>
