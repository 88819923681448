<template>
  <div :class="['modifier-set', { 'inverted-modifier-set': isNoModifierSet }]">
    <br />
    <h5>
      {{ displayModifierSetName }}
      <transition name="requirement-status">
        <span
          v-if="required"
          :class="{ required, met: isRequirementMet, 'animate__animated': true, 'animate__bounceIn': isRequirementMet }"
        >
          <SvgIcon name="check-circle" v-if="isRequirementMet"></SvgIcon>
          <SvgIcon name="warning" v-else></SvgIcon>
          <span v-if="!isRequirementMet">{{ $t('required') }}</span>
        </span>
      </transition>
    </h5>
    <br />
    <p>{{ modifierSet.description }}</p>
    <p v-safe-html="instructions"></p>
    <div class="row">
      <div
        v-for="(modifier, index) in modifierSet.modifiers"
        :key="modifier.ref"
        :class="{'col-md-6': true, 'full-width': isLastOdd(index)}"
      >
        <Modifier
          :modifier="modifier"
          :parentModifierSet="modifierSet"
          :isSelected="isSelected(modifier)"
          :modifierType="modType"
          :disabled="isMaxSelected && !isSelected(modifier)"
          :selectedGraph="selectedGraphForModifier(modifier.ref)"
          :usesAutomaticNoUi="usesAutomaticNoUi"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Modifier from "./ModifierComponent.vue";

export default {
  name: "ModifierSet",
  props: {
    modifierSet: Object,
    selectedGraph: Array,
    usesAutomaticNoUi: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Modifier,
  },
  methods: {
    isSelected(modifier) {
      const isActuallySelected = this.selectedGraph.some((m) => m.modifierRef === modifier.ref);
      return this.usesAutomaticNoUi && this.isNoModifierSet ? !isActuallySelected : isActuallySelected;
    },
    selectedGraphForModifier(ref) {
      const m = this.selectedGraph.find((m) => m.modifierRef === ref);
      return m ? m.lineModifierSets : [];
    },
    isLastOdd(index) {
      return (
        this.modifierSet.modifiers.length % 2 !== 0 &&
        index === this.modifierSet.modifiers.length - 1
      );
    },
  },
  computed: {
    modType() {
      const { max } = this.modifierSet;
      if (max === 1) {
        return "radio";
      } else {
        return "checkbox";
      }
    },
    required() {
      return this.modifierSet.min > 0;
    },
    isMaxSelected() {
      return this.modType !== "radio" && this.selectedGraph.length >= this.modifierSet.max;
    },
    instructions() {
      const { min, max } = this.modifierSet;
      const totalModifiers = this.modifierSet?.modifiers?.length || 0;
      if (min === 1 && max === 1) {
        return this.$t("chooseOne");
      } else if (min === 0 && max === 1) {
        return this.$t("optional");
      } else if (min > 0 && max === undefined) {
        return `${this.$t('chooseAtLeast')} <strong>${min}</strong>`;
      } else if (min > 0 && max > min) {
        return `${this.$t('chooseBetween')} <strong>${min}</strong> - <strong>${totalModifiers}</strong>`;
      } else if (!min && max > 0 && max !== this.modifierSet.modifiers.length) {
        const maxString = max > this.modifierSet.modifiers.length ? this.modifierSet.modifiers.length : max;
        return `${this.$t('chooseUpTo')} <strong>${maxString}</strong>`;
      } else if (min > 0 && max === min) {
        return `${this.$t('choose')} <strong>${min}</strong>`;
      } else {
        return '';
      }
    },
    isRequirementMet() {
      const selectedModifiers = this.selectedGraph;
      const min = this.modifierSet.min || 0;
      const max =
        this.modifierSet.max === undefined
          ? selectedModifiers.length
          : this.modifierSet.max;
      return selectedModifiers.length >= min && selectedModifiers.length <= max;
    },
    isNoModifierSet() {
      return this.usesAutomaticNoUi && /^no\s/i.test(this.modifierSet.name);
    },
    displayModifierSetName() {
      return this.isNoModifierSet ? this.modifierSet.name.replace(/^no\s/i, 'Included ') : this.modifierSet.name;
    },
  },
};
</script>

<style scoped>
.modifier-set .row {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem; /* Negative margin to offset the padding */
}
.modifier-set .col-md-6 {
  display: flex;
  flex-direction: column;
  padding: 0.5rem; /* Add padding for equal spacing */
  margin: 0; /* Ensure no default margin is applied */
}
.modifier-set .col-md-6.full-width {
  flex: 0 0 100%;
}
.modifier-set .col-md-6 > * {
  flex: 1;
}

.requirement-status-enter-active,
.requirement-status-leave-active {
  transition: opacity 0.5s;
}
.requirement-status-enter, .requirement-status-leave-to {
  opacity: 0;
}

/* New style for inverted modifier sets */
.inverted-modifier-set {
  background-color: #f0f0f0; /* Light gray background to indicate inversion */
  border: 1px dashed #ccc; /* Dashed border to highlight the difference */
}

.inverted-modifier-set h5 {
  font-weight: 700 !important;
}


</style>