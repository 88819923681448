<template>
    <div v-if="activeOrderDetail" class="edit-form">
        <fieldset class="mb-4">
            <legend>Order Details</legend>
            <div class="mb-3">
                <label for="template" class="form-label">Type</label>
                <select id="template" v-model="activeOrderDetail.template" @change="applyTemplate(activeOrderDetail)"
                    class="form-select">
                    <option value="">Choose a template</option>
                    <option value="phone">Phone</option>
                    <option value="name">Name</option>
                    <option value="email">Email</option>
                    <option value="tableTent">Table Tent</option>
                </select>
                <small class="text-muted">Select a template to auto-fill question details based on common
                    scenarios.</small>
            </div>
            <div class="mb-3">
                <label for="startOrder" class="form-label">Question Timing</label>
                <select id="timing" v-model="activeOrderDetail.timing" class="form-select">
                    <option value="startOrder">New Order</option>
                    <option value="beforeValidate">Before Checkout</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="title" class="form-label">Ask</label>
                <input id="title" type="text" v-model="activeOrderDetail.title" class="form-control"
                    placeholder="Title" />
            </div>
            <div class="mb-3" v-if="!activeOrderDetail.orderProperty">
                <label for="posLabel" class="form-label">POS Label</label>
                <input id="posLabel" type="text" v-model="activeOrderDetail.posLabel" class="form-control"
                    placeholder="" />
            </div>
            <div class="mb-3">
                <label for="placeholder" class="form-label">Placeholder</label>
                <input id="placeholder" type="text" v-model="activeOrderDetail.placeholder" class="form-control"
                    placeholder="Placeholder" />
            </div>
            <div class="mb-3 form-check form-switch">
                <input id="required" type="checkbox" v-model="activeOrderDetail.required" class="form-check-input" />
                <label for="required" class="form-check-label">Required</label>
            </div>
        </fieldset>
        <button @click="toggleAdvanced" class="btn btn-secondary mb-3">
            {{ activeOrderDetail.showAdvanced ? 'Hide Advanced' : 'Show Advanced' }}</button>
        <div v-if="activeOrderDetail.showAdvanced" class="modal fade show" tabindex="-1"
            style="display: block; background: rgba(0, 0, 0, 0.5);">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Advanced Settings</h5>
                        <button type="button" class="btn-close" @click="toggleAdvanced"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="description" class="form-label">Instructions</label>
                            <textarea id="description" v-model="activeOrderDetail.text" class="form-control"
                                placeholder="Description"></textarea>
                        </div>
                        <div class="mb-3">
                            <label for="minLength" class="form-label">Minimum Length</label>
                            <input id="minLength" type="number" v-model="activeOrderDetail.minLength"
                                class="form-control" placeholder="Min Length" />
                        </div>
                        <div class="mb-3">
                            <label for="maxLength" class="form-label">Maximum Length</label>
                            <input id="maxLength" type="number" v-model="activeOrderDetail.maxLength"
                                class="form-control" placeholder="Max Length" />
                        </div>
                        <div class="mb-3">
                            <label for="regex" class="form-label">Validation Regex</label>
                            <input id="regex" type="text" v-model="activeOrderDetail.validationRegex"
                                class="form-control" placeholder="Regex" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="toggleAdvanced">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-outline-secondary me-2" @click="questionCancel">Cancel</button>
            <button type="submit" class="btn btn-primary" @click="questionSave">Save Changes</button>
        </div>
    </div>
    <div v-else class="row">
        <form @submit.prevent="handleSubmit">
            <fieldset class="mb-4">
                <legend>General Settings</legend>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="form-check form-switch">
                            <input type="checkbox" class="form-check-input" id="active" v-model="form.active">
                            <label for="active" class="form-check-label">Active</label>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="config.rails === 'olo'">
                        <label for="type" class="form-label">Fulfillment Type</label>
                        <select id="type" v-model="form.type" class="form-select">
                            <option value="DINE_IN">Dine In</option>
                            <option value="PICKUP">Pickup</option>
                            <option value="DELIVERY">Delivery</option>
                        </select>
                    </div>
                    <div class="col-md-6" v-if="config.rails === 'stm'">
                        <label for="type" class="form-label">SkyTab POS Order Type</label>
                        <select id="type" v-model="form.orderTypePosGuid" class="form-select">
                            <option v-for="orderType in orderTypes" :key="orderType.id" :value="orderType.id">{{
                                orderType.title }}</option>
                        </select>
                        <div class="border border-secondary mt-3 p-2 rounded">
                            <i class="bi bi-info-circle-fill me-2"></i>
                            <strong>Note:</strong> Only order types with <em>"Require Ticket Name"</em> and <em>"Require
                                Customer"</em> disabled in SkyTab will appear here.
                                <br />
                            <button type="button" class="btn btn-link p-0 mt-2" @click.prevent="refreshOrderTypes" :disabled="loading">
                                <i :class="loading ? 'bi bi-arrow-repeat spin' : 'bi bi-arrow-clockwise'"></i> Refresh
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="title" class="form-label">Display Name</label>
                    <input type="text" id="title" v-model="form.name" class="form-control"
                        :class="{ 'is-invalid': form.name.trim() === '' }" required>
                    <div v-if="form.name.trim() === ''" class="invalid-feedback">
                        Display name is required.
                    </div>
                </div>
                <div class="mb-3">
                    <label for="icon" class="form-label">Icon</label>
                    <div class="input-group">
                        <select id="icon" v-model="form.icon" class="form-select">
                            <option value="">No Icon</option>
                            <option v-for="icon in icons" :key="icon" :value="icon">{{ camelToTitle(icon) }}</option>
                        </select>
                        <span class="input-group-text">
                            <SvgIcon v-if="form.icon" :name="form.icon" width="2rem" height="2rem" />
                        </span>
                    </div>
                </div>
            </fieldset>
            <fieldset class="mb-4">
                <legend>Questions</legend>
                <p class="text-muted">Questions help collect information from customers to fulfill orders. One question
                    can be selected as the ticket name, making it easier to look up in the POS system.</p>
                <ul class="list-group">
                    <li v-for="(orderDetail, index) in form.orderDetails" :key="index"
                        class="list-group-item d-flex justify-content-between align-items-center"
                        :class="{ 'selected-ticket-name': form.ticketNameQuestion === orderDetail.id }">
                        <div class="d-flex align-items-center">
                            <input type="radio" :id="'ticketName' + index" :value="orderDetail.id"
                                v-model="form.ticketNameQuestion" class="me-2">
                            <label :for="'ticketName' + index" class="mb-0">
                                <strong>{{ orderDetail.title }}</strong>
                            </label>
                            <span v-if="orderDetail.required" class="badge bg-primary ms-2">Required</span>
                            <span v-if="form.ticketNameQuestion === orderDetail.id" class="badge bg-success ms-2">Ticket
                                Name</span>
                        </div>
                        <div>
                            <span class="btn btn-icon" @click="questionEdit(orderDetail)">
                                <SvgIcon name="edit" width="1.5rem" height="1.5rem" />
                            </span>
                            <span class="btn btn-icon" @click="questionDelete(index)">
                                <SvgIcon name="delete" width="1.5rem" height="1.5rem" />
                            </span>
                        </div>
                    </li>
                </ul>
                <button class="btn btn-primary mt-3 w-100" @click="questionNew">Add Question</button>
            </fieldset>
            <fieldset class="mb-4">
                <legend>Payment Settings</legend>
                <div class="mb-3 form-check form-switch">
                    <input type="checkbox" class="form-check-input" id="promptForTip" v-model="form.promptForTip">
                    <label for="promptForTip" class="form-check-label">Prompt for Tip</label>
                </div>
                <div v-if="form.allowedPaymentTypes.length === 0" class="text-danger mt-2">
                    At least one payment type must be selected.
                </div>
            </fieldset>
            <fieldset class="mb-4" v-if="isAdmin">
                <legend>Admin Settings</legend>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <label for="mode" class="form-label">Mode</label>
                        <select id="mode" v-model="form.view" class="form-select">
                            <option value="restaurant">Restaurant</option>
                            <option value="retail">Retail</option>
                        </select>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="priceAdjust" class="form-label">Item/Modifier Price Adjust</label>
                    <div class="input-group">
                        <div class="btn-group" role="group">
                            <div class="btn btn-outline-primary"
                                :class="{ 'active': form.priceAdjustType === 'percentUp' }"
                                @click="selectPriceAdjustType('percentUp')">
                                +%
                            </div>
                            <div class="btn btn-outline-primary"
                                :class="{ 'active': form.priceAdjustType === 'percentDecrease' }"
                                @click="selectPriceAdjustType('percentDecrease')"
                                style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
                                -%
                            </div>
                        </div>
                        <input type="number" v-model="form.priceAdjustAmount" class="form-control" placeholder="0"
                            style="border-top-left-radius: 0; border-bottom-left-radius: 0;" />
                    </div>
                </div>
                <div class="mb-3 form-check form-switch">
                    <input id="barcodeScan" type="checkbox" v-model="form.barcodeScan" class="form-check-input" />
                    <label for="barcodeScan" class="form-check-label">Enable Barcode Scan</label>
                </div>
                <div class="mb-3 form-check form-switch">
                    <input id="promptCoupon" type="checkbox" v-model="form.promptCoupon" class="form-check-input" />
                    <label for="promptCoupon" class="form-check-label">Prompt for coupon before checkout</label>
                </div>
            </fieldset>
            <div class="text-end">
                <button type="button" class="btn btn-outline-secondary me-2" @click="cancel">Cancel</button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
        </form>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';
import { toCamelCase } from '@/utils/helpers';
import { mapGetters } from 'vuex';
export default {
    props: {
        orderType: {
            type: Object,
            default: () => ({})
        },
        paymentTypes: {
            type: Array,
            default: () => []
        }
    },
    components: {
    },
    watch: {
        'form.ticketNameQuestion'(newVal) {
            this.setTicketNameQuestion(newVal);
        },
    },
    computed: {
        ...mapState('admin', ['config', 'orderTypes']),
        ...mapGetters('admin', ['isAdmin']),
        userPaymentTypes() {
            return this.paymentTypes.filter(paymentType => paymentType.type !== 'NON_CASH');
        }
    },
    data() {
        return {
            form: {
                active: true,
                type: 'PICKUP',
                name: '',
                prepTime: '0',
                priceAdjustValue: 0,
                priceAdjustType: 'amount',
                icon: '',
                view: 'restaurant',
                allowedPaymentTypes: ['CREDIT'],
                orderDetails: [],
                isValidForm: true,
                orderTypePosGuid: null,
                ticketNameQuestion: null,
                barcodeScan: false,
                promptCoupon: false,
            },
            icons: ['forHere', 'toGo', 'barcode', 'barcodeScanner', 'employee', 'user'],
            activeOrderDetail: null,
            templates: {
                phone: {
                    "title": "What is your Phone Number?",
                    "text": "So we can text you when the order is ready...",
                    "placeholder": "(123) 456-7890",
                    "required": false,
                    "minLength": null,
                    "useAsTicketName": false,
                    "maxLength": 30,
                    "inputType": "tel",
                    "posLabel": "Phone",
                    "orderProperty": "phone",
                    "timing": "startOrder",
                    "responseType": "tel",
                    "validationRegex": "^[0-9]{3}-[0-9]{3}-[0-9]{4}$"
                },
                name: {
                    "title": "What is your Name?",
                    "text": "",
                    "placeholder": "Mr. Smith",
                    "required": false,
                    "minLength": 2,
                    "useAsTicketName": false,
                    "maxLength": 30,
                    "inputType": "text",
                    "posLabel": "Phone",
                    "orderProperty": "name",
                    "timing": "startOrder",
                    "responseType": "text",
                    "validationRegex": "^[a-zA-Z ]{2,30}$"
                },
                email: {
                    "title": "What is your Email?",
                    "text": "",
                    "placeholder": "name@email.com",
                    "required": false,
                    "minLength": null,
                    "useAsTicketName": false,
                    "maxLength": 254,
                    "inputType": "email",
                    "orderProperty": "email",
                    "timing": "startOrder",
                    "responseType": "text",
                    "validationRegex": "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
                },
                tableTent: {
                    "title": "Table Tent Number",
                    "text": "Grab a table tent and enter the number here so we can bring your order to you!",
                    "placeholder": "123",
                    "required": true,
                    "minLength": 3,
                    "useAsTicketName": false,
                    "maxLength": 3,
                    "inputType": "number",
                    "timing": "beforeValidate",
                    "posLabel": "Table Tent",
                    "responseType": "number",
                    "validationRegex": "^[0-9]{1,3}$"
                },
                advanced: {
                    "title": "",
                    "text": "",
                    "placeholder": "",
                    "required": false,
                    "minLength": 3,
                    "useAsTicketName": false,
                    "maxLength": 30,
                    "inputType": "text",
                    "timing": "startOrder",
                    "responseType": "text",
                    "validationRegex": null
                }
            },
            isValidDisplayName: true,
            isValidPaymentTypes: true,
            isValidOrderType: true,
            isValidFulfillmentType: true,
            loading: false
        };
    },
    methods: {
        toCamelCase,
        selectPriceAdjustType(type) {
            this.form.priceAdjustType = type;
            this.adjustPrice();
        },
        adjustPrice() {
            const amount = parseFloat(this.form.priceAdjustValue);
            switch (this.form.priceAdjustType) {
                case 'percentUp':
                    // Handle percentage increase adjustment
                    this.form.priceAdjustAmount = amount * 0.01;
                    break;
                case 'percentDecrease':
                    // Handle percentage decrease adjustment
                    this.form.priceAdjustAmount = -amount * 0.01;
                    break;
                default:
                    // Handle fixed amount adjustment
                    this.form.priceAdjustAmount = amount;
            }
        },
        validateForm() {
            // Check required fields
            this.isValidDisplayName = this.form.name.trim() !== '';
            this.isValidOrderType = this.config.rails === 'stm' ? this.form.orderTypePosGuid !== null : true;
            this.isValidFulfillmentType = this.config.rails === 'olo' ? this.form.type !== '' : true;

            // Update validation state
            this.isValidForm = this.isValidDisplayName && this.isValidOrderType && this.isValidFulfillmentType;

            // Check each order detail if required
            this.form.orderDetails.forEach(detail => {
                detail.isValid = detail.title.trim() !== '';
            });

            return this.isValidForm && this.form.orderDetails.every(detail => detail.isValid);
        },
        handleSubmit() {
            if (this.validateForm()) {
                this.$emit('save', this.form);
            } else {
                // Handle validation failure (e.g., show an error message)
                alert('Please correct the errors before submitting.');
            }
        },
        setTicketNameQuestion(selectedId) {
            this.form.orderDetails.forEach(detail => {
                detail.useAsTicketName = detail.id === selectedId;
            });
            // Update the model to reflect the current selection
            this.form.ticketNameQuestion = selectedId;
        },
        handlePreview() {

        },
        toggleAdvanced() {
            this.activeOrderDetail.showAdvanced = !this.activeOrderDetail.showAdvanced;
        },
        questionSave() {
            if (!this.activeOrderDetail.id) {
                this.activeOrderDetail.id = uuidv4();
            }

            if (this.activeOrderDetail.title.trim() !== '') {
                this.activeOrderDetail = null;
            } else {
                alert('The question title is required.');
            }
        },
        questionCancel() {
            if (this.activeOrderDetail && !this.activeOrderDetail.id) {
                const index = this.form.orderDetails.indexOf(this.activeOrderDetail);
                if (index > -1) {
                    this.form.orderDetails.splice(index, 1);
                }
            }
            this.activeOrderDetail = null;
        },
        questionEdit(detail) {
            this.activeOrderDetail = detail;
        },
        applyTemplate(detail) {
            Object.assign(detail, this.templates[detail.template]);
        },
        questionNew() {
            const newDetail = {
                title: '',
                text: '',
                placeholder: '',
                timing: 'startOrder',
                required: false,
                showAdvanced: false,
                orderProperty: '',
                posLabel: '',
                id: null
            };
            this.form.orderDetails.push(newDetail);
            this.questionEdit(newDetail);
        },
        isEdit(orderDetail) {
            return orderDetail === this.activeOrderDetail;
        },
        cancel() {
            this.$emit('close');
            this.resetForm();
        },
        camelToTitle(str) {
            return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
        },
        resetForm() {
            this.form = { active: true, type: '', name: '', prepTime: '5', icon: '', view: 'restaurant', allowedPaymentTypes: [] };
        },
        questionDelete(index) {
            if (confirm('Are you sure you want to delete this question?')) {
                this.form.orderDetails.splice(index, 1);
                this.activeOrderDetail = null;
            }
        },
        refreshOrderTypes() {
            this.loading = true;
            this.$store.dispatch('admin/fetchOrderTypes').finally(() => {
                this.loading = false;
            });
        }
    },
    created() {
        this.form = { ...this.form, ...this.orderType };
        if (this.orderType.allowedPaymentTypes) {
            this.form.allowedPaymentTypes = [...this.orderType.allowedPaymentTypes];
        }
    }
}
</script>
<style scoped>
h3 {
    margin-top: 2rem;
}

.list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.25rem;
    border: 1px solid #ccc;
    margin-bottom: 0rem;
    border-top: 1px solid #ccc;
}

.list-group:first-child {
    /* only border radius on top */
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.list-group:last-child {
    /* no border radius on top */
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.btn-icon {
    background: none;
    border: none;
    padding: 0;
    background: rgba(0, 0, 0, 0.04);
    opacity: 0.5;
}

.btn-icon:hover {
    opacity: 1;
}

.payment-type-card {
    border: 0.125rem solid #ddd;
    border-radius: 0.5rem;
    width: 100%;
    margin-bottom: 1rem;
    display: block;
}

.selected-payment-type {
    border-color: #007BFF;
    background-color: #E7F1FF;
}

.payment-type-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1rem;
}

.form-check-input {
    margin-top: 0;
    position: static;
    opacity: 1;
}

.btn-group .btn-outline-primary.active {
    background-color: var(--primary-color) !important;
    color: var(--primary-standoff) !important;
}

.selected-ticket-name {
    background-color: #E7F1FF;
    border-color: #007BFF;
}

.spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
