<template>
  <div class="controls" :class="{ vertical: screenOrientation == 'vertical' }">
    <div class="shopping-bag">
      <span class="description">{{ formattedCartTitle }}</span>
      <span class="count">({{ orderCount }})</span>
    </div>
    
    <!-- Use computed property to decide between buttons or dropdown -->
    <div v-if="!useDropdown" class="button-group">
      <button v-for="ot in orderTypes" :key="ot.type" class="btn"
        :class="{ 'selected': selected === ot.id, 'btn-secondary': selected !== ot.type }" 
        @click="selectOrderType(ot)">
        {{ ot.name }}
      </button>
    </div>

    <div v-else class="button-group listbox">
      <div class="order-type-picker" @click.stop="toggleDropdown">
        <button class="selected-type" aria-haspopup="listbox" :aria-expanded="dropdownOpen">
          <span class="type-name">{{ selectedOrderTypeName }}</span>
          <span class="type-icon">
            <SvgIcon name="down" :class="{ 'rotated': dropdownOpen }" />
          </span>
        </button>
        
        <ul v-if="dropdownOpen" class="order-type-list" role="listbox" @click.stop>
          <li v-for="ot in orderTypes" 
              :key="ot.id"
              @click="selectOrderType(ot)"
              :class="{ selected: selected === ot.id }"
              role="option"
              :aria-selected="selected === ot.id">
            {{ ot.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    customerName: {
      type: String,
      required: false,
    },
    cartTitle: {
      type: String,
      required: true,
    },
    orderTypes: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      default: ""
    },
    orderCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dropdownOpen: false
    }
  },
  computed: {
    ...mapGetters(['screenOrientation']),
    formattedCartTitle() {
      if (this.cartTitle.toLowerCase().startsWith('your') && this.customerName) {
        const firstName = this.customerName.split(' ')[0];
        return this.cartTitle.replace(/^your/i, firstName);
      }
      return this.cartTitle;
    },
    selectedOrderTypeName() {
      const selected = this.orderTypes.find(ot => ot.id === this.selected);
      return selected ? selected.name : 'Select Order Type';
    },
    useDropdown() {
      const maxButtonLength = 20; // Adjust this threshold as needed
      const totalLength = this.orderTypes.reduce((acc, ot) => acc + ot.name.length, 0);
      return this.orderTypes.length > 2 || totalLength > maxButtonLength;
    }
  },
  methods: {
    selectOrderType(orderType) {
      if(orderType.id !== this.selected) {
        this.$emit('order-type-selected', orderType);
      }
      this.dropdownOpen = false;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      if (this.dropdownOpen) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.dropdownOpen = false;
        document.removeEventListener('click', this.handleClickOutside);
      }
    }
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped>
.controls {
  z-index: 5000;
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1.5rem 1rem 1.5rem;
  background-color: var(--primary-white);
  display: flex;
}

.shopping-bag {
  display: flex;
  align-items: center;
  font-size: 1.625rem;
  line-height: 2.25rem;
  margin-right: 2rem;
}

.shopping-bag .description {
  margin-right: 0.25rem;
  font-weight: 700;
}

.controls.vertical {
  background-color: transparent;
  width: 40vw;
  margin-top: 5rem;
  position: absolute;
  right: 0;
  top: 0;
  border-bottom: none;
}

.btn {
  white-space: nowrap;
  min-width: 9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 14rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
  letter-spacing: 0.01125rem;
}

.button-group {
  background-color: var(--grey-focus);
  padding: 0.25rem;
  border-radius: var(--border-radius-sm);
  color: var(--primary-black);
  margin-left: 0;
  border-radius: 30px;
}

.start-over .btn-outline-primary {
  background: var(--primary-white);
  border-radius: var(--border-radius-sm);
  border: 0.125rem solid var(--grey-outline) !important;
  fill: var(--primary-color);
  font-weight: 700;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
}

.start-over .btn-outline-primary:hover {
  color: var(--primary-color);
  background-color: var(--grey-focus);
}

.button-group .btn {
  background-color: var(--grey-focus);
  color: var(--primary-black);
  font-size: 1.5rem;
  font-weight: 700;
  border: none;
  border-radius: var(--border-radius-sm);
  border-radius: 30px;
  margin-right: 0.5rem;
  transition: background-color 0.3s, color 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 10ch; /* Limit visible length to 10 characters */
}

.button-group .btn:last-child {
  margin-right: 0;
}

.selected {
  background-color: var(--primary-white) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button-group.listbox {
  width: 20rem;
  background-color: var(--grey-focus);
  padding: 0.25rem;
  border-radius: 30px;
}

.order-type-picker {
  position: relative;
  width: 100%;
}

.selected-type {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-white);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 30px;
  font-size: 1.5rem;
  font-weight: 700;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.type-name {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 2rem); /* Leave space for the icon */
}

.type-icon {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.order-type-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 0.5rem;
  background-color: var(--primary-white);
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  list-style: none;
  padding: 0;
  z-index: 1000;
  overflow: hidden; /* Ensures child borders stay within radius */
}

.order-type-list li {
  padding: 1rem 1.25rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order-type-list li:hover {
  background-color: var(--grey-focus);
}

.order-type-list li.selected {
  font-weight: 700;
  background-color: var(--grey-focus);
  position: relative;
}

.order-type-list li.selected::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 60%;
  background-color: var(--primary-color);
  border-radius: 0 2px 2px 0;
}

/* Add subtle dividers between items */
.order-type-list li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

</style>